import Field from 'Component/Field';
import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

/** @namespace AdvoxBasic/Component/ProductAttributeValue/Component/ProductAttributeValue */
export class ProductAttributeValue extends SourceProductAttributeValue {
    renderDropdown(value) {
        const { isSelected } = this.props;

        return (
            <Field
                id={value}
                name={value}
                type="checkbox"
                label={value}
                value={value}
                mix={{
                    block: 'ProductAttributeValue',
                    elem: 'Text',
                    mods: { isSelected },
                }}
                checked={isSelected}
            />
        );
    }
}

export default ProductAttributeValue;
