import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace AdvoxBasic/Component/FieldSelect/Container/FieldSelectContainer */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    isSelectDisabled() {
        const { selectOptions, disabled } = this.props;

        return selectOptions.length === 0 || !!disabled;
    }
}

export default FieldSelectContainer;
