import { lazy } from 'react';
import { Route } from 'react-router-dom';

import AnnouncementNotice from 'Component/AnnouncementNotice';
import Breadcrumbs from 'Component/Breadcrumbs';
import {
    AFTER_ITEMS_TYPE,
    ANNOUNCEMENT_NOTICE,
    BEFORE_ITEMS_TYPE,
    BRANDS_PAGE,
    BREADCRUMBS,
    DEMO_NOTICE,
    FOOTER,
    HEADER,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    SWITCH_ITEMS_TYPE,
} from 'Component/Router/Router.config';
import { Footer, Router as SourceRouter, withStoreRegex } from 'SourceComponent/Router/Router.component';

export const BrandsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/BrandsPage'));

export const DemoNotice = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/DemoNotice')
);
export const Header = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Header'));
export const NewVersionPopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NewVersionPopup')
);
export const NotificationList = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NotificationList')
);
export const BrandPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Route/BrandPage'));

/** @namespace AdvoxBasic/Component/Router/Component/Router */
export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST,
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: DEMO_NOTICE,
        },
        {
            component: <AnnouncementNotice />,
            position: 19,
            name: ANNOUNCEMENT_NOTICE,
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER,
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: BREADCRUMBS,
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP,
        },
    ];

    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component: (
                <Route path={withStoreRegex('/brands/:brand')} exact render={(props) => <BrandPage {...props} />} />
            ),
            position: 85,
        },
        {
            component: <Route path={withStoreRegex('/brands')} render={(props) => <BrandsPage {...props} />} />,
            position: 86,
            name: BRANDS_PAGE,
        },
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER,
        },
    ];
}

export default Router;
