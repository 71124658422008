// eslint-disable-next-line no-unused-vars
import * as LottiePlayer from "@lottiefiles/lottie-player";

import { LOADER_DATA } from 'Component/Loader/Loader.config';
import {
    Loader as SourceLoader,
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace AdvoxBasic/Component/Loader/Component/Loader */
export class Loader extends SourceLoader {
    renderMain() {
        return (
            <lottie-player
                src={LOADER_DATA}
                background="transparent"
                speed="1"
                loop
                autoplay
            />
        );
    }
};

export default Loader;
