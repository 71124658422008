import { CART_TOTALS, updateCartTotals, updateShippingPrice } from 'SourceStore/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import { INACTIVE_COUPON_CODE, PURCHASED_CART_TOTALS, UPDATE_SHIPPING_PRICE, UPDATE_TOTALS } from './Cart.action';

export { updateCartTotals, updateShippingPrice, CART_TOTALS };

/** @namespace AdvoxBasic/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {},
    purchasedCartTotals: {},
    inactiveCouponCode: null,
});

/** @namespace AdvoxBasic/Store/Cart/Reducer/CartReducer */
export const CartReducer = (state = getInitialState(), action) => {
    const { type, couponCode: inactiveCouponCode } = action;

    switch (type) {
        case PURCHASED_CART_TOTALS: {
            return {
                ...state,
                purchasedCartTotals: state.cartTotals || {},
            };
        }
        case UPDATE_TOTALS:
            return {
                ...state,
                ...updateCartTotals(action, state),
            };
        case UPDATE_SHIPPING_PRICE:
            return {
                ...state,
                ...updateShippingPrice(action, state),
            };
        case INACTIVE_COUPON_CODE:
            return {
                ...state,
                inactiveCouponCode,
            };
        default:
            return state;
    }
};

export default CartReducer;
