import { isMobile as sourceIsMobile } from 'SourceUtil/Mobile/isMobile';

export * from 'SourceUtil/Mobile/isMobile';

export const isMobile = {
    ...sourceIsMobile,
    any: () => window.matchMedia('(max-width: 767px)').matches,
    tablet: () => window.matchMedia('(min-width:768px) and (max-width: 1023px)').matches,
};

export default isMobile;
