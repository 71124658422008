import { postFetch } from '@scandipwa/scandipwa/src/util/Request/Request';

import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { isSignedIn } from 'Util/Auth';
import {
    formatURI,
    getFetch,
    getGraphqlEndpoint,
    getWindowId,
    handleConnectionError,
    HTTP_201_CREATED,
    HTTP_410_GONE,
    putPersistedQuery,
} from 'Util/Request';
import getStore from 'Util/Store';

export * from 'SourceUtil/Request/Request';

export const HTTP_401_UNAUTHORIZED = 401;
export const GRAPHQL_AUTHORIZATION = 'graphql-authorization';

/** @namespace AdvoxBasic/Util/Request/checkForErrors */
export const checkForErrors = (res) =>
    new Promise((resolve, reject) => {
        const { errors, data } = res;

        if ((errors ?? []).find((error) => error.extensions.category === GRAPHQL_AUTHORIZATION)) {
            if (isSignedIn()) {
                MyAccountDispatcher.logout(true, getStore().dispatch);
            }
        }

        return errors ? reject(errors) : resolve(data);
    });

/** @namespace AdvoxBasic/Util/Request/parseResponse */
export const parseResponse = (promise) =>
    new Promise((resolve, reject) => {
        promise.then(
            /** @namespace AdvoxBasic/Util/Request/then */
            (res) =>
                res.json().then(
                    /** @namespace AdvoxBasic/Util/Request/json/then */
                    (res) => resolve(checkForErrors(res)),
                    /** @namespace AdvoxBasic/Util/Request/json/then */
                    () => handleConnectionError('Can not transform JSON!') && reject()
                ),
            /** @namespace AdvoxBasic/Util/Request/then */
            (err) => handleConnectionError('Can not establish connection!') && reject(err)
        );
    });

/** @namespace AdvoxBasic/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(
        new Promise((resolve) => {
            getFetch(uri, name).then(
                /** @namespace AdvoxBasic/Util/Request/getFetch/then */
                (res) => {
                    if (res.status === HTTP_410_GONE) {
                        putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                            /** @namespace AdvoxBasic/Util/Request/putPersistedQuery/then */
                            (putResponse) => {
                                if (putResponse.status === HTTP_201_CREATED) {
                                    getFetch(uri, name).then(
                                        /** @namespace AdvoxBasic/Util/Request/getFetch/then */
                                        (res) => resolve(res)
                                    );
                                }
                            }
                        );
                    } else if (res.status === HTTP_401_UNAUTHORIZED) {
                        if (isSignedIn()) {
                            MyAccountDispatcher.logout(true, getStore().dispatch);
                        }
                    } else {
                        resolve(res);
                    }
                }
            );
        })
    );
};

/** @namespace AdvoxBasic/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables));
};

/** @namespace AdvoxBasic/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) =>
    new Promise((resolve) => {
        const { BroadcastChannel } = window;
        const windowId = getWindowId();

        if (BroadcastChannel) {
            const bc = new BroadcastChannel(`${name}_${windowId}`);
            bc.onmessage = (update) => {
                const {
                    data: { payload: body },
                } = update;

                resolve(checkForErrors(body));
            };
        }
    });
