import { HeartIcon as SourceHeartIcon } from 'SourceComponent/HeartIcon/HeartIcon.component';

import './HeartIcon.override.style';

/** @namespace AdvoxBasic/Component/HeartIcon/Component/HeartIcon */
export class HeartIcon extends SourceHeartIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
                block="HeartIcon"
                mods={{ isActive }}
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.5837 2.25876C13.5067 1.38761 14.7279 0.902344 15.9971 0.902344C17.2663 0.902344 18.4875 1.38761 19.4105 2.25876C19.8579 2.67933 20.2144 3.18704 20.4581 3.7506C20.7017 4.31417 20.8275 4.92166 20.8275 5.53566C20.8275 6.14965 20.7017 6.75715 20.4581 7.32071C20.2144 7.88428 19.8579 8.39199 19.4105 8.81255"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5834 2.25879L11.173 3.39606"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.75649 2.25943C8.83399 1.38849 7.61334 0.90332 6.34467 0.90332C5.07599 0.90332 3.85535 1.38849 2.93284 2.25943C2.4855 2.67999 2.12901 3.1877 1.88533 3.75127C1.64164 4.31483 1.51593 4.92233 1.51593 5.53632C1.51593 6.15032 1.64164 6.75781 1.88533 7.32138C2.12901 7.88494 2.4855 8.39265 2.93284 8.81322"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.75647 2.25879L11.1732 3.39606"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.93274 8.81229L10.309 16.5805C10.4185 16.6996 10.5516 16.7948 10.6998 16.8599C10.848 16.9249 11.0081 16.9585 11.17 16.9585C11.3318 16.9585 11.4919 16.9249 11.6401 16.8599C11.7883 16.7948 11.9214 16.6996 12.0309 16.5805L19.42 8.80908"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default HeartIcon;
