import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { SearchItem as SourceSearchItem } from 'SourceComponent/SearchItem/SearchItem.component';

import './SearchItem.override.style';

/** @namespace AdvoxBasic/Component/SearchItem/Component/SearchItem */
export class SearchItem extends SourceSearchItem {
    renderContent() {
        const {
            product: { name, price_range, price_tiers },
        } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                <h4 block="SearchItem" elem="Title" mods={{ isLoaded: !!name }}>
                    <TextPlaceholder content={name} length="long" />
                </h4>
                <ProductPrice
                    price={price_range}
                    price_tiers={price_tiers}
                    mix={{ block: 'SearchItem', elem: 'Price' }}
                />
            </figcaption>
        );
    }
}

export default SearchItem;
