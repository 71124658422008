import { ChildrenType } from '@scandipwa/scandipwa/src/type/Common';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Accordion from './Accordion.component';

/** @namespace AdvoxBasic/Component/Accordion/Container/AccordionContainer */
export class AccordionContainer extends PureComponent {
    static propTypes = {
        'data-accordion': PropTypes.string.isRequired,
        children: ChildrenType.isRequired,
    };

    state = {
        activeAccordionId: '',
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this),
    };

    containerProps = () => {
        const { activeAccordionId } = this.state;
        const { children } = this.props;

        return {
            children,
            activeAccordionId,
        };
    };

    handleClick(id) {
        this.setState((prevState) => ({
            activeAccordionId: prevState.activeAccordionId !== id ? id : '',
        }));
    }

    render() {
        return <Accordion {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default AccordionContainer;
