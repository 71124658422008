import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const ANNOUNCEMENT_NOTICE_DURATION = ONE_MONTH_IN_SECONDS;

export const ANNOUNCEMENT_LOCATIONS = {
    CATEGORY: 'category',
    CMS_PAGE: 'cms',
    PRODUCT: 'product',
    SEARCH: 'search',
    CART: 'cart',
};

export const CART_PATH = '/cart';
export const CONTACT_PATH = '/contact';
export const SEARCH_PATH = '/search';
