import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { getDiscountFromLowestPriceTiers } from 'Util/Price';

export { mapDispatchToProps, mapStateToProps };

/** @namespace AdvoxBasic/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    containerProps() {
        const {
            price: {
                minimum_price: {
                    final_price: { value: minimalPriceValue = 0, currency: priceCurrency = '' } = {},
                    regular_price: { value: regularPriceValue = 0 } = {},
                    default_price: { value: defaultPriceValue = 0 } = {},
                    default_final_price_excl_tax: { value: defaultFinalPriceExclTax = 0 } = {},
                } = {},
            } = {},
            isSchemaRequired,
            label,
            mix,
            price,
            price_tiers,
            variantsCount,
        } = this.props;

        if ((!minimalPriceValue || !regularPriceValue) && !defaultPriceValue) {
            return {};
        }

        const roundedRegularPrice = this.getRoundedRegularPrice();
        const formattedFinalPrice = this.getFormattedFinalPrice();
        const formattedSubPrice = this.getFormattedSubPrice();
        const discount = this.getDiscountValue();

        return {
            roundedRegularPrice,
            priceCurrency,
            defaultFinalPriceExclTax,
            discountPercentage: discount,
            formattedFinalPrice,
            formattedSubPrice,
            isSchemaRequired,
            label,
            mix,
            price,
            price_tiers,
            variantsCount,
        };
    }

    getDiscountValue() {
        const { price: { minimum_price: { discount: { percent_off } } = {} } = {}, price_tiers } = this.props;

        if (price_tiers.length) {
            const priceTierPercentOff = getDiscountFromLowestPriceTiers(price_tiers);

            return priceTierPercentOff;
        }

        return percent_off;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
