import PropTypes from 'prop-types';

import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

/** @namespace AdvoxBasic/Component/Image/Component/Image */
export class Image extends SourceImage {
    static propTypes = {
        ...super.propTypes,
        imageRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    };

    static defaultProps = {
        alt: '',
        wrapperSize: {},
        style: {},
        title: null,
        className: '',
        isPlaceholder: false,
        isPlain: false,
    };
}

export default Image;
