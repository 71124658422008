import { CheckoutSuccess as SourceCheckoutSuccess } from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';

import './CheckoutSuccess.override.style';

/** @namespace AdvoxBasic/Component/CheckoutSuccess/Component/CheckoutSuccess */
export class CheckoutSuccess extends SourceCheckoutSuccess {
    render() {
        const { orderID } = this.props;

        return (
            <div block="CheckoutSuccess">
                <h3>
                    {__('Your order number is: ')}
                    <span>{`#${orderID}`}</span>
                </h3>
                <p>{__('We`ll email you an order confirmation with details and tracking info.')}</p>
                {this.renderButtons()}
                {this.renderCreateAccountButton()}
            </div>
        );
    }
}

export default CheckoutSuccess;
