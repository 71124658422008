import { Field } from 'Util/Query';

/** @namespace AdvoxBasic/Query/AdvoxCeneoOpinie/Query/AdvoxCeneoOpinieQuery */
export class AdvoxCeneoOpinieQuery {
    getAdvoxCeneoOpinieConfigData() {
        return new Field('storeConfig').addFieldList(this._getStoreConfigFields());
    }

    getAdvoxCeneoOpinieQuery(orderId) {
        return new Field('getCeneoOpinie')
            .addArgument('orderIncrementId', 'String!', orderId)
            .addFieldList(this._getgetAdvoxCeneoOpinieFields());
    }

    setCeneoOpinieMutation(cartId, agreement) {
        const mutation = new Field('setQuoteAgreement');
        this._setCeneoOpinieMutationArguments(mutation, cartId, agreement);
        mutation.addField(this._getAgreementField());

        return mutation;
    }

    _setCeneoOpinieMutationArguments(mutation, cartId, agreement) {
        return mutation.addArgument('cartId', 'String!', cartId).addArgument('agreement', 'Boolean!', agreement);
    }

    _getAgreementField() {
        return new Field('agreement');
    }

    _getgetAdvoxCeneoOpinieFields() {
        return [
            'show_script',
            'customer_email',
            'order_increment_id',
            'shop_product_ids',
            'work_days_to_send_questionnaire',
            'script_url',
        ];
    }

    _getStoreConfigFields() {
        return ['ceneo_opinie_enabled', 'ceneo_opinie_guid', 'ceneo_opinie_agreement'];
    }
}

export default new AdvoxCeneoOpinieQuery();
