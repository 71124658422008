import PropTypes from 'prop-types';

import { ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer } from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { AttributeType } from 'Type/ProductList';

/** @namespace AdvoxBasic/Component/ProductConfigurableAttributes/Container/ProductConfigurableAttributesContainer */
export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {
    static propTypes = {
        ...SourceProductConfigurableAttributesContainer.propTypes,
        configurable_options: PropTypes.arrayOf(PropTypes.objectOf(AttributeType)).isRequired,
    };
}

export default ProductConfigurableAttributesContainer;
