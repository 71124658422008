import PropTypes from 'prop-types';

import { FieldInputContainer as SourceFieldInputContainer } from 'SourceComponent/FieldInput/FieldInput.container';

/** @namespace AdvoxBasic/Component/FieldInput/Container/FieldInputContainer */
export class FieldInputContainer extends SourceFieldInputContainer {
    static propTypes = {
        ...SourceFieldInputContainer.propTypes,
        inputMask: PropTypes.string,
        maskRegExp: PropTypes.string,
        maskChar: PropTypes.string,
    };

    static defaultProps = {
        ...SourceFieldInputContainer.defaultProps,
        inputMask: '',
        maskRegExp: '',
        maskChar: null,
    };

    containerProps = () => {
        const { disabled, skipValue, ariaLabel, inputMask, maskChar, maskRegExp, ...validProps } = this.props;

        const ariaLabelProp = ariaLabel ? { 'aria-label': ariaLabel } : {};

        return {
            ...validProps,
            ...ariaLabelProp,
            disabled,
            'data-skip-value': skipValue,
            autoComplete: this.getAutocomplete(),
            inputMask,
            maskChar,
            maskRegExp,
        };
    };
}

export default FieldInputContainer;
