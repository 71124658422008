import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    RouterContainer,
} from 'SourceComponent/Router/Router.container';
import { updateConfigDevice } from 'Store/Config/Config.action';
import { isMobile } from 'Util/Mobile';

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace AdvoxBasic/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateConfigDevice: (device) => dispatch(updateConfigDevice({ ...device, isTablet: isMobile.tablet() })),
    init: () => {
        sourceMapDispatchToProps(dispatch).init();
        ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.getCompareList(dispatch));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
