import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore';
import AdvoxFreeDeliveryReducer from 'Store/AdvoxFreeDelivery/AdvoxFreeDelivery.reducer';
import AnnouncementReducer from 'Store/Announcement/Announcement.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import RmaReducer from 'Store/Rma/Rma.reducer';

/** @namespace AdvoxBasic/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    AnnouncementReducer,
    AdvoxFreeDeliveryReducer,
    RmaReducer,
    CategoryReducer,
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(([name, reducer]) => {
        // eslint-disable-next-line no-param-reassign
        store.asyncReducers[name] = reducer;
        store.injectReducer(name, reducer);
    });

    return store;
}
