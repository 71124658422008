import PropTypes from 'prop-types';
import { Children, isValidElement, PureComponent } from 'react';

import './Accordion.style';

/** @namespace AdvoxBasic/Component/Accordion/Component/AccordionComponent */
export class AccordionComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        handleClick: PropTypes.func.isRequired,
        activeAccordionId: PropTypes.string,
    };

    renderChildren() {
        const { children, activeAccordionId, handleClick } = this.props;

        return Children.map(children, (child) => {
            const { props, key } = child || {};
            const { children } = props || {};

            if (isValidElement(child)) {
                if (child?.type === 'div' && child?.props?.className === 'Accordion-Box' && children) {
                    return (
                        <div
                            block="Accordion"
                            elem="Box"
                            key={key}
                            onClick={() => handleClick(key)}
                            mods={{ isOpen: activeAccordionId === key }}
                        >
                            {children}
                        </div>
                    );
                }

                return child;
            }

            return null;
        });
    }

    render() {
        return <div block="Accordion">{this.renderChildren()}</div>;
    }
}

export default AccordionComponent;
