import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';
import browserHistory from 'Util/History';

export { mapStateToProps, mapDispatchToProps };

/** @namespace AdvoxBasic/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    containerFunctions = {
        ...this.containerFunctions,
        handleRelatedProductClick: this.handleRelatedProductClick.bind(this),
        handleRelatedProductHover: this.handleRelatedProductHover.bind(this),
    };

    handleRelatedProductClick(url, e) {
        e.preventDefault();
        browserHistory.push(url);
    }

    handleRelatedProductHover(thumbnail) {
        const imageFromRef = this.imageRef?.current?.firstChild;
        if (imageFromRef) {
            imageFromRef.src = thumbnail.url;
            imageFromRef.alt = thumbnail.label;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
