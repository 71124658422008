import { CART_PATH, CONTACT_PATH, SEARCH_PATH } from 'Component/AnnouncementNotice/Announcement.config';
import AnnouncementQuery from 'Query/Announcement.query';
import { setAnnouncement, setCurrentLocation } from 'Store/Announcement/Announcement.action';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
/** @namespace AdvoxBasic/Store/Announcement/Dispatcher/AnnouncementDispatcher */
export class AnnouncementDispatcher {
    getAnnouncement(date, store, place, url, dispatch) {
        if (!place) {
            return null;
        }

        return executeGet(prepareQuery(AnnouncementQuery.getAnnouncementQuery(date, store, place, url), true)).then(
            /** @namespace AdvoxBasic/Store/Announcement/Dispatcher/executeGet/then */
            (response) => dispatch(setAnnouncement(response?.announcement)),
            /** @namespace AdvoxBasic/Store/Announcement/Dispatcher/executeGet/then */
            (error) => console.error('Error fetching announcement', error[0]?.message, error[0]?.debugMessage)
        );
    }

    getCurrentLocation(url, dispatch) {
        return executeGet(prepareQuery(AnnouncementQuery.getCurrentLocation(url || '/'), true)).then(
            /** @namespace AdvoxBasic/Store/Announcement/Dispatcher/executeGet/then */
            ({ urlResolver }) => {
                if (urlResolver?.type) {
                    return dispatch(setCurrentLocation(urlResolver?.type));
                }

                const {
                    location: { href },
                } = window;

                // if UrlResolver type is null check if other supported paths
                if (href.includes(CONTACT_PATH)) {
                    return dispatch(setCurrentLocation('CMS_PAGE'));
                }

                if (href.includes(SEARCH_PATH)) {
                    return dispatch(setCurrentLocation('SEARCH'));
                }

                if (href.includes(CART_PATH)) {
                    return dispatch(setCurrentLocation('CART'));
                }

                return dispatch(setCurrentLocation(''));
            },
            /** @namespace AdvoxBasic/Store/Announcement/Dispatcher/executeGet/then */
            (error) => console.error('Error fetching announcement', error[0]?.message, error[0]?.debugMessage)
        );
    }
}

export default new AnnouncementDispatcher();
