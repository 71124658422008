import Field from 'Component/Field';
import { ProductConfigurableAttributeDropdown as SourceProductConfigurableAttributeDropdown } from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

import './ProductConfigurableAttributeDropdown.override.style';

/** @namespace AdvoxBasic/Component/ProductConfigurableAttributeDropdown/Component/ProductConfigurableAttributeDropdown */
export class ProductConfigurableAttributeDropdown extends SourceProductConfigurableAttributeDropdown {
    render() {
        const { selectOptions, selectValue, selectName, onChange } = this.props;

        return (
            <Field
                id={selectName}
                name={selectName}
                type="select"
                placeholder={__('Choose')}
                mix={{ block: 'ProductConfigurableAttributeDropdown' }}
                selectOptions={selectOptions}
                value={selectValue}
                onChange={onChange}
            />
        );
    }
}

export default ProductConfigurableAttributeDropdown;
