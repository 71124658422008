import {
    SET_ADVOX_CONSENTS,
    SET_AGREEMENTS_STATUS,
    SET_NEWSLETTER_STATUS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS,
} from './Checkout.action';

/** @namespace AdvoxBasic/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    isAllRequiredAgreementsSelected: false,
    isNewsletterSelected: false,
    advoxConsents: [],
});

/** @namespace AdvoxBasic/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_SHIPPING_FIELDS:
            const { shippingFields } = action;

            return {
                ...state,
                shippingFields,
            };

        case UPDATE_EMAIL:
            const { email } = action;

            return {
                ...state,
                email,
            };

        case UPDATE_EMAIL_AVAILABLE:
            const { isEmailAvailable } = action;

            return {
                ...state,
                isEmailAvailable,
            };

        case SET_AGREEMENTS_STATUS:
            const { isAllRequiredAgreementsSelected } = action;

            return {
                ...state,
                isAllRequiredAgreementsSelected,
            };

        case SET_NEWSLETTER_STATUS:
            const { isNewsletterSelected } = action;

            return {
                ...state,
                isNewsletterSelected,
            };

        case SET_ADVOX_CONSENTS:
            const { selectedAdvoxConsents } = action;

            return {
                ...state,
                selectedAdvoxConsents,
            };

        default:
            return state;
    }
};

export default CheckoutReducer;
