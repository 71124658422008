import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { iconNamePropTypes } from 'Component/Icon/Icon.config';

import Icon from './Icon.component';
import * as IconsConfig from './Icon.config';

/** @namespace AdvoxBasic/Component/Icon/Container/IconContainer */
export class IconContainer extends PureComponent {
    static propTypes = {
        name: PropTypes.oneOf(iconNamePropTypes).isRequired,
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        cursor: PropTypes.string,
    };

    static defaultProps = {
        fill: '#E65724',
        width: '25',
        height: '25',
        cursor: 'pointer',
    };

    containerProps = () => {
        const { name, ...rest } = this.props;

        return {
            icon: this._getIconByName(name, rest),
        };
    };

    _getIconByName(name, props) {
        if (name) {
            const nameUpperCase = name.toUpperCase();
            if (typeof IconsConfig[nameUpperCase] === 'function') {
                return IconsConfig[nameUpperCase](props);
            }
        }

        return '';
    }

    render() {
        return <Icon {...this.containerProps()} />;
    }
}

export default IconContainer;
