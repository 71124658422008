import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icon from 'Component/Icon';

import './AnnouncementNotice.style';

/** @namespace AdvoxBasic/Component/AnnouncementNotice/Component/AnnouncementNoticeComponent */
export class AnnouncementNoticeComponent extends PureComponent {
    static propTypes = {
        announcement: PropTypes.shape({
            background_color: PropTypes.string,
            can_close: PropTypes.bool,
            content: PropTypes.string,
            display_from: PropTypes.string,
            display_to: PropTypes.string,
            places: PropTypes.string,
            text_color: PropTypes.string,
        }),
        hideAnnouncementNotice: PropTypes.func,
    };

    static defaultProps = {
        announcement: {
            background_color: '',
            can_close: true,
            content: '',
            display_from: '',
            display_to: '',
            places: '',
            text_color: '',
        },
        hideAnnouncementNotice: () => {},
    };

    componentDidMount() {
        this.setAnnouncementHeightToOffsetHeight();
    }

    setAnnouncementHeightToOffsetHeight() {
        const announcementNotice = document.getElementById('AnnouncementNotice');
        const announcementNoticeOffsetVar = '--demo-notice-height';
        const announcementNoticeOffsetHeight = `${announcementNotice?.offsetHeight}px`;

        announcementNotice?.style.setProperty(announcementNoticeOffsetVar, announcementNoticeOffsetHeight);
        document.documentElement.style.setProperty(announcementNoticeOffsetVar, announcementNoticeOffsetHeight);
    }

    renderContent() {
        const { announcement } = this.props;
        const { background_color, content } = announcement || {};

        if (!content) {
            return null;
        }

        return (
            <div id="AnnouncementNotice" block="AnnouncementNotice" style={{ backgroundColor: background_color }}>
                {this.renderHtmlContent()}
                {this.renderHideNotice()}
            </div>
        );
    }

    renderHtmlContent() {
        const { announcement } = this.props;
        const { content, text_color } = announcement || {};

        return (
            <div
                block="AnnouncementNotice"
                elem="Content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: content }}
                style={{ color: text_color }}
            />
        );
    }

    renderHideNotice() {
        const { announcement, hideAnnouncementNotice } = this.props;
        const { can_close, text_color } = announcement || {};

        if (!can_close) {
            return null;
        }

        return (
            <button block="AnnouncementNotice" elem="CloseIcon" onClick={hideAnnouncementNotice}>
                <Icon name="close" fill={text_color} />
            </button>
        );
    }

    render() {
        return <>{this.renderContent()}</>;
    }
}

export default AnnouncementNoticeComponent;
