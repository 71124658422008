import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace AdvoxBasic/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    getCreateAccountMutation(options) {
        const { customer, password, ...rest } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password, ...rest })
            .addField(this._getCustomerField());
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            'company',
            this._getRegionField(),
        ];
    }
}

export default new MyAccountQuery();
