import BrowserDatabase from 'Util/BrowserDatabase';

export * from 'SourceUtil/Price/Price.config';
export * from 'SourceUtil/Price/Price';

/** @namespace AdvoxBasic/Util/Price/getCurrentLang */
export const getCurrentLang = () => {
    const config = BrowserDatabase.getItem('config');

    if (config) {
        const { code } = config.storeConfig;
        const parts = code.split('_');

        return parts[parts - 1];
    }

    return window.defaultLocale.split('_')[0];
};

/** @namespace AdvoxBasic/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    if (!price && price !== 0) {
        return null;
    }

    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
};

/** @namespace AdvoxBasic/Util/Price/getDiscountFromLowestPriceTiers */
export const getDiscountFromLowestPriceTiers = (price_tiers) => {
    const lowestPriceTiers = price_tiers.reduce(
        (acc, currentValue) => (acc.final_price.value < currentValue.final_price.value ? acc : currentValue),
        price_tiers[0]
    );

    const {
        discount: { percent_off },
    } = lowestPriceTiers;

    return percent_off;
};
