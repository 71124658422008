import de_DE from 'date-fns/locale/de';
import en_GB from 'date-fns/locale/en-GB';
import en_US from 'date-fns/locale/en-US';
import pl_PL from 'date-fns/locale/pl';
import ro_RO from 'date-fns/locale/ro';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';

import FieldDatePicker from './FieldDatePicker.component';

registerLocale('en_GB', en_GB);
registerLocale('en_US', en_US);
registerLocale('de_DE', de_DE);
registerLocale('ro_RO', ro_RO);
registerLocale('pl_PL', pl_PL);

/** @namespace AdvoxBasic/Component/FieldDatePicker/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentStoreCode: state.ConfigReducer.code,
    locale: state.ConfigReducer.locale,
});

/** @namespace AdvoxBasic/Component/FieldDatePicker/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace AdvoxBasic/Component/FieldDatePicker/Container/FieldDatePickerContainer */
export class FieldDatePickerContainer extends PureComponent {
    static propTypes = {
        dateFormat: PropTypes.string,
    };

    containerFunctions = {
        onEvent: this.onEvent.bind(this),
    };

    state = {
        value: '',
    };

    containerProps() {
        const { locale, dateFormat } = this.props;
        const { value } = this.state;

        return {
            ...this.props,
            value,
            locale: locale || window.defaultLocale,
            dateFormat,
        };
    }

    onEvent(eventName) {
        return (value, event) => {
            const { props } = this;

            if (eventName === 'onChange') {
                this.setState({ value });
            }

            if (typeof props[eventName] === 'function') {
                props[eventName](event, value.toISOString());
            }
        };
    }

    render() {
        return <FieldDatePicker {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDatePickerContainer);
