import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BrandsQuery from 'Query/Brands.query';
import DataContainer from 'Util/Request/DataContainer';

import Brands from './Brands.component';
import { BRANDS_SLIDER } from './Brands.config';

/** @namespace AdvoxBasic/Component/Brands/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentStoreId: state.ConfigReducer.id,
});
/** @namespace AdvoxBasic/Component/Brands/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace AdvoxBasic/Component/Brands/Container/BrandsContainer */
export class BrandsContainer extends DataContainer {
    static propTypes = {
        isFeatured: PropTypes.bool,
        currentStoreId: PropTypes.number,
        variant: PropTypes.string,
    };

    static defaultProps = {
        currentStoreId: 1,
        isFeatured: true,
        variant: BRANDS_SLIDER,
    };

    state = {
        brands: [],
    };

    componentDidMount() {
        this.getBrands();
    }

    containerProps = () => {
        const { brands } = this.state;
        const { variant } = this.props;

        return { brands, variant };
    };

    getBrands() {
        const { isFeatured, currentStoreId } = this.props;

        this.fetchData(
            [BrandsQuery.getBrandsQuery({ isFeatured, storeId: currentStoreId })],
            ({ mpbrand: { items } }) => this.setState({ brands: items })
        );
    }

    render() {
        return <Brands {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsContainer);
