import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AdvoxSlider from 'Component/AdvoxSlider';
import Link from 'Component/Link';

import { BRANDS_SLIDER_SETTINGS, BRANDS_URL } from './Brands.config';

import './Brands.style';

/** @namespace AdvoxBasic/Component/Brands/Component/Brands */
export class Brands extends PureComponent {
    static propTypes = {
        brands: PropTypes.arrayOf(
            PropTypes.shape({
                option_id: PropTypes.number,
                value: PropTypes.string,
                meta_description: PropTypes.string,
                url_key: PropTypes.string,
            })
        ),
        variant: PropTypes.string,
    };

    renderBrandItem({ option_id, value, meta_description, image, url_key }) {
        return (
            <div key={option_id} block="Brands" elem="Item">
                {image && (
                    <div block="Brands" elem="ImgWrapper">
                        <Link to={`${BRANDS_URL}/${url_key}`}>
                            <img src={image} alt={meta_description} />
                        </Link>
                    </div>
                )}
                <Link to={`${BRANDS_URL}/${url_key}`}>{value}</Link>
            </div>
        );
    }

    renderBrandItems() {
        const { brands } = this.props;

        return brands?.length && brands.map((brand) => this.renderBrandItem(brand));
    }

    renderContent() {
        const { variant } = this.props;

        if (variant === 'BRANDS_GRID') {
            return (
                <div block="Brands" elem="Grid">
                    {this.renderBrandItems()}
                </div>
            );
        }

        return (
            <AdvoxSlider settings={BRANDS_SLIDER_SETTINGS} variant="default" heading={__('Brand zone')}>
                {this.renderBrandItems()}
            </AdvoxSlider>
        );
    }

    render() {
        const { variant, brands } = this.props;

        if (!brands.length) {
            return null;
        }

        const isSlider = variant === 'BRANDS_SLIDER';

        return (
            <div block="Brands" mods={{ isSlider }}>
                {this.renderContent()}
            </div>
        );
    }
}

export default Brands;
