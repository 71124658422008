import { getInitialState } from '@scandipwa/scandipwa/src/store/ProductList/ProductList.reducer';

export const RESET_PRODUCT_LIST = 'RESET_PRODUCT_LIST';

const ProductListReducer_reducer = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { type } = action;

    if (type !== RESET_PRODUCT_LIST) {
        return originalUpdatedState;
    }

    return getInitialState();
};

export default {
    'Store/ProductList/Reducer': {
        function: ProductListReducer_reducer,
    },
};
