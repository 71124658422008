import Icon from 'Component/Icon';
import { ProductWishlistButton as SourceProductWishlistButton } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

/** @namespace AdvoxBasic/Component/ProductWishlistButton/Component/ProductWishlistButton */
export class ProductWishlistButton extends SourceProductWishlistButton {
    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
                block="ProductWishlistButton"
                elem="Button"
                mods={{ isInWishlist, isDisabled }}
                mix={{ block: 'Button', mix }}
                title={this.getTitle()}
                onClick={this.onClick}
            >
                <Icon name="heart" />
            </button>
        );
    }
}

export default ProductWishlistButton;
