import { LOADING_CURRENT_CATEGORY, UPDATE_CURRENT_CATEGORY } from 'Store/Category/Category.action';

/** @namespace AdvoxBasic/Store/Category/Reducer/getInitialState */
export const getInitialState = () => ({
    category: {},
    isLoading: true,
});

/** @namespace AdvoxBasic/Store/Category/Reducer/CategoryReducer */
export const CategoryReducer = (state = getInitialState(), { type, category }) => {
    switch (type) {
        case LOADING_CURRENT_CATEGORY:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_CURRENT_CATEGORY:
            return {
                ...state,
                category: { ...category },
                isLoading: false,
            };

        default:
            return state;
    }
};

export default CategoryReducer;
