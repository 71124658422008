import { connect } from 'react-redux';

import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import isMobile from 'Util/Mobile';

export { mapDispatchToProps, mapStateToProps };

/** @namespace AdvoxBasic/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    containerProps() {
        const { product, mix, disabled, layout, isWithIcon, isBtnTextLong } = this.props;
        const { isLoading } = this.state;

        return {
            isLoading,
            product,
            mix,
            disabled,
            layout,
            isWithIcon,
            isBtnTextLong,
            isMobile,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
