export * from 'SourceComponent/Header/Header.config';

export const MOBILE_MENU_VARIANT = 'right';
export const MOBILE_MINICART_VARIANT = 'right';
export const WISHLIST_LINK = 'my-account/my-wishlist';
export const COMPARE_LINK = 'compare';
export const CONTACT_LINK = 'contact';
export const MINICART_KEY = 'minicart';
export const MOBILE_MY_ACCOUNT_VARIANT = 'right';
export const MY_ACCOUNT_KEY = 'account';
export const HELP_OVERLAY = 'help_overlay';
export const SHOW_ICON_TITLE = true;
