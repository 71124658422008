import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import zxcvbn from 'zxcvbn';

import './PasswordStrength.style';

/** @namespace AdvoxBasic/Component/PasswordStrength/Component/PasswordStrength */
export class PasswordStrength extends PureComponent {
    static propTypes = {
        password: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    };

    state = {
        testedResult: '',
        type: '',
    };

    createPasswordLabel = this.createPasswordLabel.bind(this);

    /* eslint-disable */
    createPasswordLabel(result) {
        switch (result.score) {
            case 0:
                return __('Password type weak');
            case 1:
                return __('Password type weak');
            case 2:
                return __('Password type good');
            case 3:
                return __('Password type good');
            case 4:
                return __('Password type strong');
            default:
                return __('Password type weak');
        }
    }

    /* eslint-enable */

    renderContent() {
        const { password, id } = this.props;
        const { testedResult, type } = this.state;

        if (password) {
            const newTestedResult = zxcvbn(password);
            if (newTestedResult.score !== testedResult.score) {
                const newType = this.createPasswordLabel(newTestedResult);
                this.setState((prevState) => ({
                    ...prevState,
                    testedResult: newTestedResult,
                    type: newType,
                }));
            }
        }

        return testedResult ? (
            <>
                <progress block="PasswordStrength" elem="Progress" mods={{ type }} value={testedResult.score || 1} />
                <label block="PasswordStrength" elem="Label" mods={{ type }} htmlFor={id} id={id}>
                    {__('Password strength: ')}
                    <span>{type}</span>
                </label>
            </>
        ) : null;
    }

    render() {
        return <div block="PasswordStrength">{this.renderContent()}</div>;
    }
}

export default PasswordStrength;
