import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { DeviceType } from 'Type/Device';

import './CategoryBanner.style';

/** @namespace AdvoxBasic/Component/CategoryBanner/Component/CategoryBanner */
export class CategoryBanner extends PureComponent {
    static propTypes = {
        bannerHref: PropTypes.string,
        desktopImageSrc: PropTypes.string,
        desktopImageAlt: PropTypes.string,
        mobileImageSrc: PropTypes.string,
        mobileImageAlt: PropTypes.string,
        device: DeviceType.isRequired,
    };

    renderContent() {
        const { bannerHref, desktopImageSrc, desktopImageAlt, mobileImageSrc, mobileImageAlt, device } = this.props;

        if (!desktopImageSrc && !mobileImageSrc) {
            return null;
        }

        if ((device.isMobile || device.isTablet) && mobileImageSrc) {
            return (
                <div block="CategoryBanner">
                    <Link to={bannerHref}>
                        <img src={mobileImageSrc} alt={mobileImageAlt} />
                    </Link>
                </div>
            );
        }

        if (desktopImageSrc) {
            return (
                <div block="CategoryBanner">
                    <Link to={bannerHref}>
                        <img src={desktopImageSrc} alt={desktopImageAlt} />
                    </Link>
                </div>
            );
        }
    }

    render() {
        return <>{this.renderContent()}</>;
    }
}

export default CategoryBanner;
