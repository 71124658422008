import { RESET_PRODUCT_LIST } from '@advox/storefront/src/plugin/ProductList.plugin.js';

import MyAccountQuery from 'Query/MyAccount.query';
import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ProductCompareDispatcher,
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { updateCustomerDetails, updateCustomerSignInStatus, updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { CartDispatcher, CUSTOMER, WishlistDispatcher } from 'Store/MyAccount/MyAccount.dispatcher';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import { deleteAuthorizationToken, setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { fetchMutation } from 'Util/Request';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

/** @namespace AdvoxBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    logout(authTokenExpired = false, dispatch) {
        if (authTokenExpired) {
            dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            this.handleForceRedirectToLoginPage();
        } else {
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }

        if (!window.dataCache) {
            window.dataCache = {};
        } else {
            window.dataCache = {};
        }

        deleteGuestQuoteId();
        deleteAuthorizationToken();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        CartDispatcher.then(({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        });
        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.resetWishlist(dispatch));

        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
        dispatch({ type: RESET_PRODUCT_LIST });
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const {
            generateCustomerToken: { token },
        } = result;

        setAuthorizationToken(token);

        dispatch({ type: RESET_PRODUCT_LIST });
        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));

        ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }
}

export default new MyAccountDispatcher();
