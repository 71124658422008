import React, { forwardRef, PureComponent } from 'react';
import DatePicker from 'react-datepicker';

import { FORMAT_DATE } from './FieldDatePicker.config';

import './FieldDatePicker.style';

export const RenderCustomInput = forwardRef(({ formRef, ...events }, ref) => (
    <div block="FieldDatePicker">
        <input
            block="input-control"
            type="text"
            ref={(node) => {
                ref.current = node;
                formRef.current = node;
            }}
            {...events}
        />
    </div>
));

/** @namespace AdvoxBasic/Component/FieldDatePicker/Component/FieldDatePicker */
export class FieldDatePicker extends PureComponent {
    render() {
        const { label, placeholder, disabled, onEvent, value, formRef, locale, dateFormat, ...props } = this.props;

        return (
            <DatePicker
                {...props}
                onChange={onEvent('onChange')}
                disabled={disabled}
                customInput={<RenderCustomInput formRef={formRef} />}
                dateFormat={dateFormat || FORMAT_DATE}
                locale={locale}
                autoComplete="off"
                placeholderText={label || placeholder || __('Select date')}
                selected={value}
            />
        );
    }
}

export default FieldDatePicker;
