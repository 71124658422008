import { connect } from 'react-redux';

import CategoryBanner from './CategoryBanner.component';

/** @namespace AdvoxBasic/Component/CategoryBanner/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace AdvoxBasic/Component/CategoryBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBanner);
