import {
    IN_STOCK,
    OPTION_TYPE_COLOR,
    OPTION_TYPE_IMAGE,
    OPTION_TYPE_TEXT,
    OUT_OF_STOCK,
    validOptionTypes,
} from 'SourceComponent/ProductCard/ProductCard.config';

export { IN_STOCK, OPTION_TYPE_COLOR, OPTION_TYPE_IMAGE, OPTION_TYPE_TEXT, OUT_OF_STOCK, validOptionTypes };

export const MAX_RELATED_PRODUCT_TO_SHOW = 3;
