export const PROPS_TO_REMOVE = [
    'customValidationStatus',
    'fileExtensions',
    'isSubmitted',
    'isPasswordStrengthVisible',
    'onChangeCheckbox',
    'maskChar',
    'filename',
    'subLabel',
    'validationStatus',
    'validateSeparately',
    'selectOptions',
    'onKeyEnterDown',
    'isLabelWithArrow',
    'handleChange',
    'formRefMap',
    'numberWithoutButtons',
    'noMinMax',
    'maskRegExp',
    'dateFormat',
    'isDisabled',
    'hiddenFilename'
]
