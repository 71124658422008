import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace AdvoxBasic/Query/Order/Query/OrderQuery */
export class OrderQuery extends SourceOrderQuery {
    /* prettier-ignore */
    _getOrderItemsFields(isList) {
        return [
            this._getBaseOrderInfoField(isList),
            ...(!isList
                ? [
                    this._getPaymentInfoField(),
                    this._prepareShippingInfo(),
                    this._getOrderProductsField(),
                    this._getOrderPaymentLink(),
                ]
                : []),
        ];
    }

    getReorderItemsMutation(orderNumber) {
        const mutation = new Field('reorderItems');
        mutation.addArgument('orderNumber', 'String!', orderNumber);
        mutation.addField(this._getReorderItemsMutationField());

        return mutation;
    }

    _getReorderItemsMutationField() {
        return new Field('cart').addFieldList(['id']);
    }

    _getOrderPaymentLink() {
        return new Field('payment_link');
    }
}

export default new OrderQuery();
