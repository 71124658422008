import Icon from 'Component/Icon';
import Loader from 'SourceComponent/Loader';
import { ProductCompareButton as SourceProductCompareButton } from 'SourceComponent/ProductCompareButton/ProductCompareButton.component';

import './ProductCompareButton.override.style';

/** @namespace AdvoxBasic/Component/ProductCompareButton/Component/ProductCompareButton */
export class ProductCompareButton extends SourceProductCompareButton {
    render() {
        const { handleClick, isLoading, isActive, mix } = this.props;

        return (
            <div block="ProductCompareButton" mods={{ isActive }} mix={mix}>
                <button
                    block="ProductCompareButton"
                    elem="Button"
                    onClick={handleClick}
                    mix={{ block: 'Button' }}
                    aria-label={__('Compare')}
                >
                    <Icon name="compare" />
                    <Loader isLoading={isLoading} />
                </button>
            </div>
        );
    }
}

export default ProductCompareButton;
