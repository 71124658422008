import PropTypes from 'prop-types';

import Icon from 'Component/Icon';
import { ProductReviewRating as SourceProductReviewRating } from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from './ProductReviewRating.config';

import './ProductReviewRating.override.style';

/** @namespace AdvoxBasic/Component/ProductReviewRating/Component/ProductReviewRating */
export class ProductReviewRating extends SourceProductReviewRating {
    static propTypes = {
        ...super.propTypes,
        showCounterInBrackets: PropTypes.bool,
    };

    static defaultProps = {
        ...super.defaultProps,
        showCounterInBrackets: false,
    };

    renderStar(count, type) {
        return Array.from(Array(count), (_, i) => <Icon key={i} name={type} />);
    }

    render() {
        const { summary, code, placeholder, mix, count, showCounterInBrackets } = this.props;

        const [fullCount, halfFullCount, emptyCount] = this.getStarCounts();

        const ariaText = this.getAriaText(summary, code);

        if (placeholder) {
            return this.renderPlaceholder();
        }

        const countLabel = {
            1: __('single review'),
            2: __('reviews'),
        };

        return (
            <div
                block="ProductReviewRating"
                title={`${summary}%`}
                ref={this.reviewRating}
                aria-label={ariaText}
                mix={mix}
            >
                {this.renderStar(fullCount, STAR_FULL)}
                {this.renderStar(halfFullCount, STAR_HALF_FULL)}
                {this.renderStar(emptyCount, STAR_EMPTY)}
                <span block="ProductReviewRating" elem="Counter" mods={{ isCounterInBrackets: showCounterInBrackets }}>
                    {showCounterInBrackets
                        ? `(${count})`
                        : `${count} ${countLabel[count] ? countLabel[count] : __('plural reviews')}`}
                </span>
            </div>
        );
    }
}

export default ProductReviewRating;
