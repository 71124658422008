import PropTypes from 'prop-types';

import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.style';

/** @namespace AdvoxBasic/Component/FieldSelect/Component/FieldSelect */
export class FieldSelect extends SourceFieldSelect {
    static propTypes = {
        ...super.propTypes,
        placeholder: PropTypes.string,
    };

    renderNativeSelect() {
        const {
            name,
            id,
            onChange,
            selectOptions,
            formRef,
            value,
            isSelectExpanded: isExpanded,
            autocomplete,
            skipValue,
            placeholder,
            isDisabled,
        } = this.props;

        return (
            <select
                block="FieldSelect"
                elem="Select"
                autoComplete={autocomplete}
                mods={{ isExpanded, isPlaceholder: placeholder && !value }}
                ref={formRef}
                name={name}
                id={id}
                disabled={isDisabled}
                tabIndex="0"
                value={value || ''}
                onChange={onChange}
                data-skip-value={skipValue}
                aria-label={__('Select drop-down')}
            >
                {this.renderPlaceholder()}
                {selectOptions.map(this.renderNativeOption)}
            </select>
        );
    }
}

export default FieldSelect;
