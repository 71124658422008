import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth';
import { getGuestQuoteId } from '@scandipwa/scandipwa/src/util/Cart';
import { fetchMutation, fetchQuery, getErrorMessage } from '@scandipwa/scandipwa/src/util/Request';

import { FREE_DELIVERY_ENABLED } from 'Component/AdvoxFreeDelivery/AdvoxFreeDelivery.config';
import CartQuery from 'Query/Cart.query';
import OrderQuery from 'Query/Order.query';
import { CartDispatcher as SourceCartDispatcher } from 'SourceStore/Cart/Cart.dispatcher';
import { updateTotals } from 'Store/Cart/Cart.action';
import BrowserDatabase from 'Util/BrowserDatabase';

export const AdvoxFreeDeliveryDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/AdvoxFreeDelivery/AdvoxFreeDelivery.dispatcher'
);

/** @namespace AdvoxBasic/Store/Cart/Dispatcher/CartDispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async addOrderItemsToCart(dispatch, orderNr) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return Promise.reject();
            }

            const id = await fetchMutation(OrderQuery.getReorderItemsMutation(orderNr));

            const { cartData = {} } = await fetchQuery(CartQuery.getCartQuery(id));

            dispatch(showNotification('success', __('Products added to cart.')));
            return this._updateCartData(cartData, dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }
    }

    _updateCartData(cartData, dispatch) {
        dispatch(updateTotals(cartData));

        // avoid free delivery request if cart empty
        // make request if there are changes to cart items qty
        if (cartData && cartData.items_qty > 0) {
            const isFreeDeliveryAvailableOrNotSetYet = BrowserDatabase.getItem(FREE_DELIVERY_ENABLED) !== false;

            if (isFreeDeliveryAvailableOrNotSetYet) {
                AdvoxFreeDeliveryDispatcher.then(({ default: dispatcher }) =>
                    dispatcher.getAdvoxFreeDelivery(dispatch)
                );
            }
        }
    }
}

export default new CartDispatcher();
