import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './EyeIcon.style';

/** @namespace AdvoxBasic/Component/EyeIcon/Component/EyeIcon */
export class EyeIcon extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool,
    };

    static defaultProps = {
        isActive: false,
    };

    render() {
        const { isActive } = this.props;
        if (isActive) {
            return (
                <svg
                    block="EyeIcon"
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.6389 13.2278C20.9596 11.1712 22.2422 8.84082 22.2422 8.84082C22.2422 8.84082 18.149 1.38916 11.3272 1.38916C10.0168 1.39364 8.72132 1.66477 7.5206 2.18581L8.57116 3.2304C9.45451 2.91086 10.3871 2.74628 11.3272 2.74401C14.2197 2.74401 16.6196 4.32647 18.3783 6.07286C19.2247 6.91787 19.9834 7.84518 20.6431 8.84082C20.564 8.95869 20.4767 9.08875 20.3771 9.23101C19.92 9.88134 19.2446 10.7484 18.3783 11.6088C18.1531 11.8323 17.9185 12.0532 17.6729 12.2672L18.6389 13.2278Z"
                        fill="white"
                    />
                    <path
                        d="M15.8255 10.4338C16.13 9.58823 16.1864 8.67416 15.9881 7.79798C15.7899 6.9218 15.3451 6.11954 14.7056 5.48454C14.0661 4.84954 13.2582 4.40791 12.3758 4.21105C11.4934 4.01418 10.5729 4.07017 9.72132 4.3725L10.8442 5.48749C11.3685 5.41297 11.9032 5.46073 12.4057 5.62698C12.9082 5.79324 13.3648 6.07343 13.7394 6.44534C14.1139 6.81726 14.3961 7.27068 14.5635 7.76968C14.731 8.26868 14.7791 8.79954 14.704 9.3202L15.8255 10.4338ZM11.8102 12.1937L12.9317 13.3074C12.0801 13.6097 11.1596 13.6657 10.2772 13.4688C9.39482 13.2719 8.58688 12.8303 7.94739 12.1953C7.3079 11.5603 6.86315 10.758 6.6649 9.88187C6.46664 9.00569 6.52302 8.09162 6.82749 7.24601L7.95037 8.36101C7.87532 8.88167 7.92341 9.41253 8.09085 9.91153C8.25828 10.4105 8.54045 10.8639 8.91499 11.2359C9.28954 11.6078 9.74617 11.888 10.2487 12.0542C10.7512 12.2205 11.2858 12.2682 11.8102 12.1937Z"
                        fill="white"
                    />
                    <path
                        d="M4.98287 5.41334C4.73728 5.63011 4.50125 5.8496 4.27613 6.07314C3.42973 6.91815 2.67097 7.84545 2.01127 8.84109L2.27733 9.23128C2.73439 9.8816 3.40975 10.7487 4.27613 11.609C6.0348 13.3554 8.43609 14.9379 11.3272 14.9379C12.3041 14.9379 13.2237 14.7577 14.0832 14.4501L15.1338 15.4961C13.9331 16.0171 12.6375 16.2882 11.3272 16.2927C4.50534 16.2927 0.412231 8.84109 0.412231 8.84109C0.412231 8.84109 1.69337 6.5094 4.01553 4.45411L4.98151 5.41469L4.98287 5.41334ZM19.0304 17.4497L2.65798 1.19165L3.62396 0.232422L19.9964 16.4905L19.0304 17.4497Z"
                        fill="white"
                    />
                </svg>
            );
        }

        return (
            <svg
                block="EyeIcon"
                width="23"
                height="18"
                viewBox="0 0 23 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22.2422 8.40094C22.2422 8.40094 18.149 0.949219 11.3272 0.949219C4.50534 0.949219 0.412231 8.40094 0.412231 8.40094C0.412231 8.40094 4.50534 15.8527 11.3272 15.8527C18.149 15.8527 22.2422 8.40094 22.2422 8.40094ZM2.01264 8.40094C2.67233 7.40529 3.43109 6.47797 4.27749 5.63297C6.03344 3.88655 8.43473 2.30408 11.3272 2.30408C14.2197 2.30408 16.6196 3.88655 18.3783 5.63297C19.2247 6.47797 19.9834 7.40529 20.6431 8.40094C20.564 8.51882 20.4767 8.64888 20.3771 8.79114C19.92 9.44147 19.2446 10.3086 18.3783 11.1689C16.6196 12.9153 14.2183 14.4978 11.3272 14.4978C8.43473 14.4978 6.0348 12.9153 4.27613 11.1689C3.42974 10.3239 2.67097 9.39659 2.01127 8.40094H2.01264Z"
                    fill="#AF9EF0"
                />
                <path
                    d="M11.3272 5.01315C10.4226 5.01315 9.55499 5.37003 8.91532 6.00528C8.27565 6.64053 7.91628 7.50212 7.91628 8.4005C7.91628 9.29888 8.27565 10.1605 8.91532 10.7957C9.55499 11.431 10.4226 11.7879 11.3272 11.7879C12.2318 11.7879 13.0994 11.431 13.7391 10.7957C14.3788 10.1605 14.7381 9.29888 14.7381 8.4005C14.7381 7.50212 14.3788 6.64053 13.7391 6.00528C13.0994 5.37003 12.2318 5.01315 11.3272 5.01315ZM6.55191 8.4005C6.55191 7.14277 7.05502 5.93654 7.95056 5.04719C8.84611 4.15784 10.0607 3.6582 11.3272 3.6582C12.5937 3.6582 13.8083 4.15784 14.7039 5.04719C15.5994 5.93654 16.1025 7.14277 16.1025 8.4005C16.1025 9.65824 15.5994 10.8645 14.7039 11.7538C13.8083 12.6432 12.5937 13.1428 11.3272 13.1428C10.0607 13.1428 8.84611 12.6432 7.95056 11.7538C7.05502 10.8645 6.55191 9.65824 6.55191 8.4005Z"
                    fill="#AF9EF0"
                />
            </svg>
        );
    }
}

export default EyeIcon;
