import {
    SET_ANNOUNCEMENT,
    SET_ANNOUNCEMENT_BACKGROUND_COLOR,
    SET_CURRENT_LOCATION,
} from 'Store/Announcement/Announcement.action';

/** @namespace AdvoxBasic/Store/Announcement/Reducer/getInitialState */
export const getInitialState = () => ({
    announcement: null,
    announcementBackgroundColor: null,
    currentLocation: null,
});

/** @namespace AdvoxBasic/Store/Announcement/Reducer/AnnouncementReducer */
export const AnnouncementReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case SET_ANNOUNCEMENT:
            const { announcement } = action;

            return {
                ...state,
                announcement,
            };
        case SET_ANNOUNCEMENT_BACKGROUND_COLOR:
            const { announcementBackgroundColor } = action;

            return {
                ...state,
                announcementBackgroundColor,
            };
        case SET_CURRENT_LOCATION:
            const { currentLocation } = action;

            return {
                ...state,
                currentLocation,
            };
        default:
            return state;
    }
};

export default AnnouncementReducer;
