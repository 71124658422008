import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import AdvoxCeneoOpinieQuery from 'Query/AdvoxCeneoOpinie.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery, getErrorMessage } from 'Util/Request';

import CheckoutSuccess from './CheckoutSuccess.component';

/** @namespace AdvoxBasic/Component/CheckoutSuccess/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    ceneo_opinie_enabled: state.ConfigReducer.ceneo_opinie_enabled,
});

/** @namespace AdvoxBasic/Component/CheckoutSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
});

/** @namespace AdvoxBasic/Component/CheckoutSuccess/Container/CheckoutSuccessContainer */
export class CheckoutSuccessContainer extends PureComponent {
    static propTypes = {
        orderID: PropTypes.string.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.getCeneoOpinie();
    }

    componentWillUnmount() {
        if (document.getElementById('ceneoOpinieScript')) {
            document.getElementById('ceneoOpinieScript').remove();
        }

        if (document.getElementById('ceneoOpinieDataScript')) {
            document.getElementById('ceneoOpinieDataScript').remove();
        }
    }

    loadCeneoScript(ceneoOpinie) {
        const { customer_email, order_increment_id, shop_product_ids, work_days_to_send_questionnaire, script_url } =
            ceneoOpinie;

        const ceneoScript = document.createElement('script');
        const dataScript = document.createElement('script');

        ceneoScript.src = script_url;
        ceneoScript.id = 'ceneoOpinieScript';
        ceneoScript.async = true;

        dataScript.text =
            `ceneo_client_email = "${customer_email}";` +
            `ceneo_order_id = ${order_increment_id};` +
            `ceneo_shop_product_ids = "${shop_product_ids}";` +
            `ceneo_work_days_to_send_questionnaire = ${work_days_to_send_questionnaire};`;
        dataScript.id = 'ceneoOpinieDataScript';
        dataScript.async = true;

        document.body.appendChild(dataScript);
        document.body.appendChild(ceneoScript);
    }

    containerProps = () => {
        const { checkoutStep, device, orderID, isEmailAvailable, email, firstName, lastName } = this.props;

        return {
            checkoutStep,
            device,
            orderID,
            isEmailAvailable,
            email,
            firstName,
            lastName,
        };
    };

    getCeneoOpinie() {
        const { orderID, showErrorNotification, ceneo_opinie_enabled } = this.props;

        if (ceneo_opinie_enabled) {
            return fetchQuery(AdvoxCeneoOpinieQuery.getAdvoxCeneoOpinieQuery(orderID)).then(
                /** @namespace AdvoxBasic/Component/CheckoutSuccess/Container/fetchQuery/then */
                ({ getCeneoOpinie }) => {
                    const { show_script } = getCeneoOpinie;

                    if (show_script) {
                        this.loadCeneoScript(getCeneoOpinie);
                    }
                },
                /** @namespace AdvoxBasic/Component/CheckoutSuccess/Container/fetchQuery/then */
                (error) => {
                    showErrorNotification(getErrorMessage(error));
                }
            );
        }
    }

    render() {
        return <CheckoutSuccess {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessContainer);
