import Icon from 'Component/Icon';
import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace AdvoxBasic/Component/AddToCart/Component/AddToCart */
export class AddToCart extends SourceAddToCart {
    renderCartIcon() {
        const { isWithIcon } = this.props;

        if (!isWithIcon) {
            return null;
        }

        return <Icon name="cart" />;
    }

    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick,
            layout,
            disabled,
            isBtnTextLong,
            isMobile,
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const btnText = isBtnTextLong && !isMobile.any() ? __('Add To Cart') : __('To cart');

        return (
            <button
                onClick={buttonClick}
                block="Button AddToCart"
                mix={mix}
                mods={{ isLoading, layout, small: true }}
                disabled={isLoading || disabled}
            >
                {this.renderCartIcon()}
                <span>{isLoading ? __('Adding...') : btnText}</span>
            </button>
        );
    }
}

export default AddToCart;
