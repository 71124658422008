import ProductListQuery from 'Query/ProductList.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace AdvoxBasic/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    _getProductField() {
        const prevProductOptions = { ...ProductListQuery.options };
        ProductListQuery.options = {
            isForLinkedProduct: true,
            withBulkPackaging: true,
        };

        const productQuery = new Field('product').addFieldList(
            ProductListQuery._getProductInterfaceFields(false, true, true)
        );

        ProductListQuery.options = prevProductOptions;

        return productQuery;
    }
}

export default new CartQuery();
