import PropTypes from 'prop-types';

import { ProductConfigurableAttributes as SourceProductConfigurableAttributes } from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import { AttributeType } from 'Type/ProductList';

import './ProductConfigurableAttributes.override.style';

/** @namespace AdvoxBasic/Component/ProductConfigurableAttributes/Component/ProductConfigurableAttributes */
export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {
    static propTypes = {
        ...SourceProductConfigurableAttributes.propTypes,
        configurable_options: PropTypes.arrayOf(PropTypes.objectOf(AttributeType)).isRequired,
    };

    renderConfigurableAttributes() {
        const {
            configurable_options,
            isExpandable,
            inStock
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const {
                attribute_code,
                attribute_label,
                attribute_options
            } = option;

            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatch(option) : this.renderDropdown(option);
            }

            if (!inStock && !isSwatch) {
                return null;
            }

            return (
                <div key={`${attribute_code}-wrapper`}>
                    <p block="ProductConfigurableAttributes" elem="Title">{ attribute_label }</p>
                    { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
                </div>
            );
        });
    }
}

export default ProductConfigurableAttributes;
