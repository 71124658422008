import { GET_RMA_DATA, UPDATE_RMA_LOAD_STATUS } from './Rma.action';

/** @namespace AdvoxBasic/Store/Rma/Reducer/getInitialState */
export const getInitialState = () => ({
    rmaData: [],
    rmaDataLoading: true,
});

/** @namespace AdvoxBasic/Store/Rma/Reducer/RmaReducer */
export const RmaReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case GET_RMA_DATA:
            const { data } = action;
            return {
                ...state,
                rmaData: data,
            };

        case UPDATE_RMA_LOAD_STATUS:
            const { isLoading } = action;

            return {
                ...state,
                rmaDataLoading: isLoading
            };

        default:
            return state;
    }
};

export default RmaReducer;
