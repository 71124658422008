import PropTypes from 'prop-types';

import validationConfig, { messagePassword } from 'Component/Form/Form.config';
import {
    EMAIL_TYPE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE,
} from 'SourceComponent/Field/Field.config';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

import { CHECKBOX_TYPE, SEARCH_TYPE } from './Field.config';

export * from 'SourceComponent/Field/Field.container';

/** @namespace AdvoxBasic/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        isPasswordStrengthVisible: PropTypes.bool,
        inputMask: PropTypes.string,
        maskRegExp: PropTypes.string,
        maskChar: PropTypes.string,
        type: PropTypes.oneOf([
            TEXT_TYPE,
            NUMBER_TYPE,
            TEXTAREA_TYPE,
            PASSWORD_TYPE,
            RADIO_TYPE,
            CHECKBOX_TYPE,
            SELECT_TYPE,
            FILE_TYPE,
            EMAIL_TYPE,
            SEARCH_TYPE,
        ]).isRequired,
        numberWithoutButtons: PropTypes.bool,
        noMinMax: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        isPasswordStrengthVisible: false,
        inputMask: '',
        maskRegExp: '',
        maskChar: null,
        numberWithoutButtons: false,
        noMinMax: false,
        validationPasswordValue: '',
    };

    containerFunctions = {
        ...this.containerFunctions,
        onBlur: this.onBlur.bind(this),
    };

    containerProps() {
        const {
            isPasswordStrengthVisible,
            inputMask,
            maskChar,
            maskRegExp,
            numberWithoutButtons,
            noMinMax,
            dateFormat,
        } = this.props;

        const { checked, validationPasswordValue } = this.state;

        return {
            ...super.containerProps(),
            checked: checked || false,
            isPasswordStrengthVisible,
            inputMask,
            maskChar,
            maskRegExp,
            numberWithoutButtons,
            noMinMax,
            dateFormat,
            validationPasswordValue,
        };
    }

    updateValidationStatus() {
        const validationRule = this.validateField();

        this.setState({
            validationStatus: !validationRule.validate,
            validationMessage: validationRule.message,
            validationPasswordValue: validationRule.passwordValue,
        });
    }

    validateField() {
        const { validation, id, formRef: refMap, formRefMap } = this.props;

        if (!validation || !id || !refMap || !refMap.current) {
            return {};
        }

        const { current: inputNode } = refMap || {};

        if (!inputNode) {
            return {};
        }

        // we are looking for validation and executing it
        const rule = validation.find((rule) => {
            if (!validationConfig[rule]) {
                return false;
            }

            const validationRules = validationConfig[rule];
            const isValid = validationRules.validate(inputNode, formRefMap);

            return !isValid;
        });

        if (rule === 'password') {
            const { message, value } = messagePassword(inputNode);

            return {
                ...validationConfig[rule],
                message,
                passwordValue: value,
            };
        }

        return validationConfig[rule] || {};
    }

    handleChange(value, shouldUpdate = true, fileValue = false) {
        const { isControlled, onChange, type, min, max, noMinMax } = this.props;

        switch (type) {
            case NUMBER_TYPE:
                const isValueNaN = Number.isNaN(parseInt(value, 10));
                if (!noMinMax && (min > value || value > max || isValueNaN)) {
                    break;
                }
                if (onChange && shouldUpdate) {
                    onChange(value);
                }
                if (!isControlled) {
                    this.setState({ value });
                }
                break;
            case FILE_TYPE:
                if (value) {
                    const result = onChange && onChange(fileValue);

                    this.setState({
                        value: result ? value : '',
                        filename: result ? value.substr(value.lastIndexOf('\\') + 1) : '',
                    });
                }

                break;
            default:
                if (onChange) {
                    onChange(value);
                }
                if (!isControlled) {
                    this.setState({ value });
                }
        }
    }
}

export default FieldContainer;
