import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    ANNOUNCEMENT_LOCATIONS,
    ANNOUNCEMENT_NOTICE_DURATION,
    CART_PATH,
    CONTACT_PATH,
    SEARCH_PATH,
} from 'Component/AnnouncementNotice/Announcement.config';
import { ANNOUNCEMENT_NOTICE } from 'Store/Announcement/Announcement.action';
import AnnouncementDispatcher from 'Store/Announcement/Announcement.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';

import AnnouncementNotice from './AnnouncementNotice.component';

/** @namespace AdvoxBasic/Component/AnnouncementNotice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getAnnouncement: (date, store, place, url) =>
        AnnouncementDispatcher.getAnnouncement(date, store, place, url, dispatch),
    getCurrentLocation: (url) => AnnouncementDispatcher.getCurrentLocation(url, dispatch),
});

/** @namespace AdvoxBasic/Component/AnnouncementNotice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    announcement: state.AnnouncementReducer.announcement,
    currentLocation: state.AnnouncementReducer.currentLocation,
    currentStoreId: state.ConfigReducer.id,
    currentStoreCode: state.ConfigReducer.code,
});

/** @namespace AdvoxBasic/Component/AnnouncementNotice/Container/AnnouncementNoticeContainer */
export class AnnouncementNoticeContainer extends PureComponent {
    static propTypes = {
        getAnnouncement: PropTypes.func,
        announcement: PropTypes.shape({
            content: PropTypes.string,
            display_from: PropTypes.string,
            display_to: PropTypes.string,
            places: PropTypes.string,
        }),
        currentLocation: PropTypes.string,
    };

    static defaultProps = {
        getAnnouncement: () => {},
        announcement: null,
        currentLocation: '',
    };

    componentDidMount() {
        history.listen(() => {
            this.checkCurrentLocation();
            this.removeAnnouncementHeight();
            this.setState({ isAnnouncementReady: false });
        });

        this.checkCurrentLocation();
    }

    componentDidUpdate(prevProps) {
        const { isAnnouncementReady } = this.state;
        const { announcement, currentLocation, currentStoreId, getAnnouncement, currentStoreCode } = this.props;

        if (prevProps.currentLocation !== currentLocation) {
            const sanitizedPathname = currentLocation.replace(`${currentStoreCode}/`, '');

            // eslint-disable-next-line
            this.setAnnouncementToReady();

            const {
                location: { href },
            } = window;

            const now = new Date();
            now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30);

            const dateString = now.toISOString().split('T')[0];
            const fullDateString = `${dateString} ${now.getHours()}:${now.getMinutes()}:00`;

            getAnnouncement(fullDateString, currentStoreId, ANNOUNCEMENT_LOCATIONS[sanitizedPathname], href);
        }

        setTimeout(() => {
            if (!isAnnouncementReady) {
                if (announcement && currentLocation) {
                    this.setAnnouncementToReady();
                }
            }
        }, 400);
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            announcementHidden: BrowserDatabase.getItem(ANNOUNCEMENT_NOTICE),
            isAnnouncementReady: false,
        };
    }

    checkCurrentLocation = () => {
        const { getCurrentLocation, currentStoreCode } = this.props;
        const { pathname, href } = window.location;
        const sanitizedPathname = pathname.replace(`${currentStoreCode}/`, '');
        const isHome = sanitizedPathname === '/';
        const paths = [CART_PATH, SEARCH_PATH, CONTACT_PATH];
        const shouldGetLocation = paths.find((path) => href.includes(path))?.length > 0 || isHome;

        // get currentLocation for pages that are not processed by UrlRewrites
        if (shouldGetLocation) {
            getCurrentLocation(sanitizedPathname);
        }
    };

    setAnnouncementToReady() {
        const { currentLocation } = this.props;
        const { announcementHidden } = this.state;
        const isAnnouncementInPlace = this.checkAnnouncementInPlace(currentLocation);

        if (isAnnouncementInPlace && announcementHidden === null) {
            this.setState({ isAnnouncementReady: true });
        } else {
            this.setState({ isAnnouncementReady: false });
            this.removeAnnouncementHeight();
        }
    }

    setAnnouncementHeightToZero() {
        const announcementNotice = document.getElementById('AnnouncementNotice');
        announcementNotice?.style.setProperty('--demo-notice-height', '0');
        document.documentElement.style.setProperty('--demo-notice-height', '0');
    }

    removeAnnouncementHeight() {
        const announcementNotice = document.getElementById('AnnouncementNotice');
        announcementNotice?.style.removeProperty('--demo-notice-height');
        document.documentElement.style.removeProperty('--demo-notice-height');
    }

    checkAnnouncementInPlace = (currentLocation) => {
        const { announcement } = this.props;
        const { places } = announcement || {};

        return places?.includes(currentLocation?.toLowerCase().slice(0, 3)) && currentLocation !== '';
    };

    hideAnnouncementNotice = () => {
        BrowserDatabase.setItem(true, ANNOUNCEMENT_NOTICE, ANNOUNCEMENT_NOTICE_DURATION);
        this.setState({
            announcementHidden: BrowserDatabase.getItem(ANNOUNCEMENT_NOTICE),
            isAnnouncementReady: false,
        });
        this.setAnnouncementHeightToZero();
    };

    render() {
        const { isAnnouncementReady } = this.state;
        const { announcement } = this.props;

        if (!isAnnouncementReady) {
            return null;
        }

        return <AnnouncementNotice announcement={announcement} hideAnnouncementNotice={this.hideAnnouncementNotice} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnnouncementNoticeContainer));
