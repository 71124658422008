export const GET_RMA_DATA = 'GET_RMA_DATA';
export const UPDATE_RMA_LOAD_STATUS = 'UPDATE_RMA_LOAD_STATUS';

/** @namespace AdvoxBasic/Store/Rma/Action/getRmaData */
export const getRmaData = (data) => ({
    type: GET_RMA_DATA,
    data,
});

/** @namespace AdvoxBasic/Store/Rma/Action/updateRmaStatus */
export const updateRmaStatus = (status) => ({
    type: UPDATE_RMA_LOAD_STATUS,
    isLoading: status,
});
