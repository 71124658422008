import { PRODUCT_REVIEWS_PER_PAGE } from 'Component/ProductReviews/ProductReviews.config';
import { NONE_SORT_OPTION_VALUE } from 'Route/SearchPage/SearchPage.config';
import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field } from 'Util/Query';
/** @namespace AdvoxBasic/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    _getFilterArgumentMap() {
        return {
            ...super._getFilterArgumentMap(),
            productsSkuArray: (skus) => ({ sku: { in: skus?.map((sku) => encodeURIComponent(sku)) || [] } }),
            productSKU: (sku) => ({ sku: { eq: encodeURIComponent(sku) } }),
        };
    }

    _getAdvoxLabelsField() {
        return new Field('labels').addFieldList(this._getAdvoxLabelsFields());
    }

    _getAdvoxLabelsFields() {
        return ['code', 'label_type', 'text', 'image', 'url', 'text_color', 'text_background_color'];
    }

    _getReviewsField() {
        const { reviewsPageSize } = this.options;

        return new Field('reviews')
            .addArgument('pageSize', 'Int', reviewsPageSize || PRODUCT_REVIEWS_PER_PAGE)
            .addArgument('currentPage', 'Int', 1)
            .addFieldList(this._getReviewsFields());
    }

    _getProductSearchInterfaceFields() {
        const fields = [
            'name',
            'url',
            'sku',
            'id',
            this._getPriceRangeField(),
            this._getTierPricesField(),
            this._getProductThumbnailField(),
        ];

        return fields;
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isCart = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            withBulkPackaging = false,
        } = this.options;

        // Basic fields returned always
        const fields = ['id', 'sku', 'name', 'type_id', 'stock_status', this._getStockItemField()];

        if (!isCart) {
            fields.push(this._getAdvoxLabelsField());
        }

        if (isCart) {
            fields.push(this._getCategoriesField(), this._getPriceRangeField());
        }

        // Additional fields, which we want to return always, except when it is on PLP
        if (!isPlp) {
            fields.push(
                this._getPriceRangeField(),
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                'special_price',
                this._getTierPricesField()
            );

            // to all products, except when it is on PLP (non-variants)
            if (!isVariant) {
                fields.push(this._getUrlRewritesFields());

                // except when it is on cart
                if (!isCart) {
                    fields.push(this._getReviewCountField(), this._getRatingSummaryField());
                }
            }
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (isPlp && !isVariant) {
            fields.push(
                this._getPriceRangeField(),
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                'special_from_date',
                'special_to_date',
                'special_price',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        if (!isVariant && withBulkPackaging) {
            fields.push('bulk_packaging');
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push('url', this._getCustomizableProductFragment(), this._getRelatedProductsByAttributeField());

            // if variants are not needed
            if (!noVariants) {
                fields.push(this._getConfigurableProductFragment(), this._getBundleProductFragment());
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                'omnibus_lowest_price',
                'delivery_time',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getCustomizableProductFragment(),
                this._getProductQtyInSource(),
                this._getCategoriesField()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField(),
                    this._getRelatedProductsByAttributeField()
                );
            }
        }

        return fields;
    }

    _getProductQtyInSource() {
        return new Field('product_qty_in_sources').addFieldList(this._getProductQtyInSourceFields());
    }

    _getProductQtyInSourceFields() {
        return ['quantity'];
    }

    _getRelatedProductsByAttributeField() {
        return new Field('related_products_by_attribute').addFieldList(this._getRelatedProductsByAttributeFields());
    }

    _getRelatedProductsByAttributeFields() {
        return ['name', 'sku', 'url', this.__getRelatedProductByAttributeThumbnailField()];
    }

    __getRelatedProductByAttributeThumbnailField() {
        return new Field('thumbnail').addFieldList(['label', 'url']);
    }

    _getItemsField() {
        const { isPlp = false, isSearchBar = false } = this.options;

        const { isSingleProduct } = this.options;

        const items = new Field('items');

        if (isSearchBar) {
            items.addFieldList(this._getProductSearchInterfaceFields());

            return items;
        }

        items.addFieldList(this._getProductInterfaceFields());

        if (isSingleProduct) {
            items.addField(this._getGroupedProductItems());
            items.addField(this._getDownloadableProductFields());
        } else {
            items.addField(this._getDownloadableProductLinksRequired());
        }

        // all products except PLP
        if (!isPlp && !isSearchBar) {
            items.addField(this._getProductBrandField());
            items.addField(this._getProductAttachmentsField());
        }

        return items;
    }

    _getProductBrandField() {
        return new Field('mpbrand').addFieldList(this._getProductBrandFields());
    }

    _getProductBrandFields() {
        return ['image', 'brand_id', 'value', 'url_key'];
    }

    _getProductAttachmentsField() {
        return new Field('mw_attachments').addFieldList(this._getProductAttachmentsFields());
    }

    _getProductAttachmentsFields() {
        return ['tab_title', 'block_title', this._getProductAttachmentsItemsField()];
    }

    _getProductAttachmentsItemsField() {
        const items = new Field('items').addFieldList(this._getProductAttachmentsItemsFields());

        return items;
    }

    _getProductAttachmentsItemsFields() {
        return ['id', 'name', 'url'];
    }

    _getArgumentsMap() {
        const { requireInfo, isBrandPage } = this.options;

        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: { type: 'Int!' },
            pageSize: {
                type: 'Int!',
                handler: (option) => (requireInfo ? 1 : option),
            },
            search: {
                type: 'String!',
                handler: (option) => option.replace(/\+/g, ' '),
            },
            sort: {
                type: 'ProductAttributeSortInput!',
                handler: ({ sortKey, sortDirection }) => {
                    if (sortKey === NONE_SORT_OPTION_VALUE) {
                        return {};
                    }

                    return { [sortKey]: sortDirection || 'ASC' };
                },
            },
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id || '0',
                    };

                    const { customFilters: { category_id, manufacturer } = {} } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */
                    if (category_id) {
                        // eslint-disable-next-line fp/no-delete
                        options.categoryIds = undefined;
                    }

                    const parsedOptions = Object.entries(options).reduce((acc, [key, option]) => {
                        // if there is no value, or if the key is just not present in options object
                        if (!option || !filterArgumentMap[key]) {
                            return acc;
                        }

                        return { ...acc, ...filterArgumentMap[key](option) };
                    }, {});

                    // add brand filter for brandPage
                    if (isBrandPage && manufacturer) {
                        parsedOptions.manufacturer = manufacturer;
                    }

                    return parsedOptions;
                },
            },
        };
    }
}

export default new ProductListQuery();
