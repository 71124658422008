import AddToCart from 'Component/AddToCart';
import Icon from 'Component/Icon';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import { MAX_RELATED_PRODUCT_TO_SHOW } from 'Component/ProductCard/ProductCard.config';
import ProductLabel from 'Component/ProductLabel';
import { PRODUCT_LABEL_TYPE } from 'Component/ProductLabel/ProductLabel.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';

import './ProductCard.override.style';

/** @namespace AdvoxBasic/Component/ProductCard/Component/ProductCard */
export class ProductCard extends SourceProductCard {
    renderMainDetails() {
        const {
            product: { name },
        } = this.props;

        return (
            <p block="ProductCard" elem="Name" mods={{ isLoaded: !!name }} title={name}>
                <TextPlaceholder content={name} length="medium" />
            </p>
        );
    }

    renderAddToCart() {
        const {
            product,
            product: { type_id, options = [], configurable_options: confOptions = {} },
            configurableVariantIndex,
            layout,
            showSelectOptionsNotification,
            inStock,
        } = this.props;

        const quantity = 1;
        const groupedProductQuantity = {};

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions,
        };

        const redirectOnConfig =
            type_id === CONFIGURABLE &&
            Object.keys(confOptions).length !== Object.keys(this.getAttributesToShow()).length;

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button block="Button AddToCart" mods={{ layout, small: true }} onClick={showSelectOptionsNotification}>
                    <Icon name="cart" />
                    {__('To cart')}
                </button>
            );
        }

        return (
            <AddToCart
                product={product}
                configurableVariantIndex={configurableVariantIndex}
                mix={{ block: 'ProductActions', elem: 'AddToCart' }}
                quantity={quantity}
                groupedProductQuantity={groupedProductQuantity}
                productOptionsData={productOptionsData}
                disabled={!inStock}
                layout={layout}
                isWithIcon
                isBtnTextLong
            />
        );
    }

    renderRelatedProductsByAttribute() {
        const { device } = this.props;
        const {
            product: { related_products_by_attribute },
        } = this.props;

        if (
            device.isMobile ||
            device.isTablet ||
            !related_products_by_attribute ||
            !related_products_by_attribute.length
        ) {
            return null;
        }

        const relatedProductsLeftToShow = related_products_by_attribute.length - MAX_RELATED_PRODUCT_TO_SHOW;
        const relatedProductsSliced = related_products_by_attribute.slice(0, MAX_RELATED_PRODUCT_TO_SHOW);

        return (
            <div block="ProductCard" elem="RelatedProductWrapper">
                <div block="ProductCard" elem="RelatedProductList">
                    {relatedProductsSliced.map((product) => this.renderRelatedProductByAttribute(product))}

                    {relatedProductsLeftToShow > 0 && (
                        <button block="ProductCard" elem="RelatedProduct" mods={{ isNumber: true }}>
                            +{relatedProductsLeftToShow}
                        </button>
                    )}
                </div>
            </div>
        );
    }

    renderRelatedProductByAttribute(product) {
        const { name, thumbnail, url, sku } = product;
        const { url: thumb_url, label } = thumbnail;
        const {
            product: { image },
            handleRelatedProductClick,
            handleRelatedProductHover,
        } = this.props;

        return (
            <button
                block="ProductCard"
                elem="RelatedProduct"
                title={name}
                key={sku}
                onClick={() => handleRelatedProductClick(url)}
                onMouseEnter={() => handleRelatedProductHover(thumbnail)}
                onMouseLeave={() => handleRelatedProductHover(image)}
            >
                <Image src={thumb_url} alt={label} />
            </button>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <>
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                    </figure>
                </div>
                {this.renderRelatedProductsByAttribute()}
                <div block="ProductCard" elem="Content">
                    {this.renderReviews()}
                    {this.renderAdditionalProductDetails()}
                    {this.renderProductPrice()}
                    {this.renderMainDetails()}
                    <div block="ProductCard" elem="Actions">
                        <div
                            block="ProductCard"
                            elem="AddToCart"
                            // Prevent a shared transition during adding to cart
                            onClick={(e) => e.stopPropagation()}
                        >
                            {this.renderAddToCart()}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderDiscountLabel() {
        const {
            product: { price_range },
        } = this.props;

        const discount = price_range?.minimum_price?.discount?.percent_off || 0;

        if (discount > 0) {
            return <ProductLabel type={PRODUCT_LABEL_TYPE.SALE} text={`-${discount.toFixed()}%`} radius="left" />;
        }
    }

    renderAdvoxLabels() {
        const {
            product: { labels },
        } = this.props;

        if (labels && labels.length !== 0) {
            return labels.map((item) => <ProductLabel key={item.code} radius="left" label={item} />);
        }

        return null;
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout,
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout,
        };

        if (layout === LIST_LAYOUT) {
            return (
                <div block="ProductCard" mods={mods} mix={mix}>
                    {this.renderProductActions()}
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </div>
            );
        }

        return (
            <div block="ProductCard" mods={mods} mix={mix}>
                {this.renderProductActions()}
                {this.renderDiscountLabel()}
                {this.renderAdvoxLabels()}
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </div>
        );
    }
}

export default ProductCard;
