import PropTypes from 'prop-types';

import { ContentWrapper as SourceContentWrapper } from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.override.style';

/** @namespace AdvoxBasic/Component/ContentWrapper/Component/ContentWrapper */
export class ContentWrapper extends SourceContentWrapper {
    static propTypes = {
        ...super.propTypes,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };
}

export default ContentWrapper;
