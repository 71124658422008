export * from 'SourceStore/Checkout/Checkout.action';

export const SET_AGREEMENTS_STATUS = 'SET_AGREEMENTS_STATUS';
export const SET_NEWSLETTER_STATUS = 'SET_NEWSLETTER_STATUS';
export const SET_ADVOX_CONSENTS = 'SET_ADVOX_CONSENTS';

/** @namespace AdvoxBasic/Store/Checkout/Action/setAgreementsStatus */
export const setAgreementsStatus = (isAllRequiredAgreementsSelected) => ({
    type: SET_AGREEMENTS_STATUS,
    isAllRequiredAgreementsSelected,
});

/** @namespace AdvoxBasic/Store/Checkout/Action/setNewsletterStatus */
export const setNewsletterStatus = (isNewsletterSelected) => ({
    type: SET_NEWSLETTER_STATUS,
    isNewsletterSelected,
});

/** @namespace AdvoxBasic/Store/Checkout/Action/setAdvoxConsents */
export const setAdvoxConsents = (selectedAdvoxConsents) => ({
    type: SET_ADVOX_CONSENTS,
    selectedAdvoxConsents,
});
