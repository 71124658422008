import PropTypes from 'prop-types';

import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.style';

/** @namespace AdvoxBasic/Component/Breadcrumb/Component/Breadcrumb */
export class Breadcrumb extends SourceBreadcrumb {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };
}

export default Breadcrumb;
