import {
    CHECKBOX_TYPE,
    EMAIL_TYPE,
    ENTER_KEY_CODE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE,
    VALIDATION_STATUS,
} from 'SourceComponent/Field/Field.config';

export {
    CHECKBOX_TYPE,
    EMAIL_TYPE,
    ENTER_KEY_CODE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE,
    VALIDATION_STATUS,
};

export const SEARCH_TYPE = 'search';
export const DATE_TYPE = 'date';
