import EyeIcon from 'Component/EyeIcon';
import {
    CHECKBOX_TYPE,
    DATE_TYPE,
    EMAIL_TYPE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SEARCH_TYPE,
    SELECT_TYPE,
    TEXTAREA_TYPE,
} from 'Component/Field/Field.config';
import FieldDatePicker from 'Component/FieldDatePicker';
import FieldInput from 'Component/FieldInput';
import Icon from 'Component/Icon';
import PasswordStrength from 'Component/PasswordStrength';
import SearchIcon from 'Component/SearchIcon';
import AddIcon from 'SourceComponent/AddIcon';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import MinusIcon from 'SourceComponent/MinusIcon';

import './Field.override.style';

/** @namespace AdvoxBasic/Component/Field/Component/Field */
export class Field extends SourceField {
    state = {
        passwordShown: false,
    };

    togglePasswordVisibility = this.togglePasswordVisibility.bind(this);

    togglePasswordVisibility() {
        this.setState((prevState) => ({
            ...prevState,
            passwordShown: !prevState.passwordShown,
        }));
    }

    renderTypePassword() {
        const { disabled, value, id, isPasswordStrengthVisible } = this.props;
        const { passwordShown } = this.state;
        const isToggleAvailable = !disabled && value !== '';

        return (
            <div block="Field" elem="InputPasswordContainer" mods={{ isDisabled: disabled }}>
                <FieldInput {...this.props} type={passwordShown ? 'text' : 'password'} />
                {this.renderLabel()}
                <button
                    block="Field"
                    elem="InputPasswordBtn"
                    type="button"
                    onClick={isToggleAvailable ? this.togglePasswordVisibility : undefined}
                >
                    <EyeIcon isActive={passwordShown} />
                </button>
                {isPasswordStrengthVisible && <PasswordStrength password={value} id={id} />}
            </div>
        );
    }

    renderTypeSearch() {
        const { onChange } = this.props;
        return (
            <>
                <FieldInput
                    {...this.props}
                    type="text"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => onChange(e.target.value)}
                />
                <SearchIcon />
            </>
        );
    }

    renderDate() {
        return <FieldDatePicker {...this.props} />;
    }

    renderInputOfType(type) {
        switch (type) {
            case CHECKBOX_TYPE:
                return this.renderCheckbox();
            case RADIO_TYPE:
                return this.renderRadioButton();
            case NUMBER_TYPE:
                return this.renderTypeNumber();
            case TEXTAREA_TYPE:
                return this.renderTextarea();
            case PASSWORD_TYPE:
                return this.renderTypePassword();
            case SEARCH_TYPE:
                return this.renderTypeSearch();
            case SELECT_TYPE:
                return this.renderSelectWithOptions();
            case EMAIL_TYPE:
                return this.renderTypeEmail();
            case FILE_TYPE:
                return this.renderFile();
            case DATE_TYPE:
                return this.renderDate();
            default:
                return this.renderTypeText();
        }
    }

    renderTypeNumber() {
        const { min, max, value, onKeyEnterDown, handleChange, numberWithoutButtons, noMinMax } = this.props;

        if (numberWithoutButtons) {
            return (
                <FieldInput
                    {...this.props}
                    block="Input"
                    mods={{ type: 'number_withoutButtons' }}
                    type="number"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDown={onKeyEnterDown}
                    aria-label={__('Value')}
                    max={noMinMax ? Infinity : max}
                />
            );
        }

        return (
            <>
                <FieldInput
                    {...this.props}
                    type="number"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => handleChange(Number(e.target.value))}
                    onKeyDown={onKeyEnterDown}
                    aria-label={__('Value')}
                />
                <button
                    disabled={+value === max}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleChange(+value + 1)}
                    aria-label={__('Add')}
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                    disabled={+value === min}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleChange(+value - 1)}
                    aria-label={__('Subtract')}
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }

    renderMessage() {
        const { message, validationPasswordValue } = this.props;

        if (!message) {
            return null;
        }

        if (validationPasswordValue) {
            return (
                <p block="Field" elem="Message">
                    {__(message, validationPasswordValue)}
                </p>
            );
        }

        return (
            <p block="Field" elem="Message">
                {message}
            </p>
        );
    }

    renderErrorIcon() {
        const { validationStatus } = this.props;

        if (validationStatus !== false) {
            return null;
        }

        return (
            <span block="Field" elem="ErrorIcon">
                <Icon name="input_error" fill="#000" height="20" />
            </span>
        );
    }

    render() {
        const { mix, type, message, validationStatus, numberWithoutButtons } = this.props;

        return (
            <div
                block="Field"
                mods={{
                    type,
                    type_number_withoutButtons: numberWithoutButtons,
                    hasError: validationStatus === false || !!message,
                    isValid: validationStatus === true,
                }}
                mix={mix}
            >
                {this.renderErrorIcon()}
                {this.renderInputOfType(type)}
                {type !== PASSWORD_TYPE && this.renderLabel()}
                {this.renderMessage()}
            </div>
        );
    }
}

export default Field;
