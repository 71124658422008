import PropTypes from 'prop-types';

import ContentWrapper from 'Component/ContentWrapper';
import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';
import { appendWithStoreCode } from 'Util/Url';

import './Breadcrumbs.override.style';

/** @namespace AdvoxBasic/Component/Breadcrumbs/Component/Breadcrumbs */
export class Breadcrumbs extends SourceBreadcrumbs {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    render() {
        const { breadcrumbs, areBreadcrumbsVisible } = this.props;

        if (
            !areBreadcrumbsVisible ||
            location.pathname === appendWithStoreCode('/') ||
            location.pathname.match(appendWithStoreCode('/account')) ||
            location.pathname === '/' ||
            breadcrumbs[0]?.url === ''
        ) {
            return null;
        }

        return (
            <ContentWrapper mix={{ block: 'Breadcrumbs' }} label={__('Breadcrumbs (current location)...')}>
                <nav aria-label="Breadcrumbs navigation">
                    <ul block="Breadcrumbs" elem="List" itemScope itemType="http://schema.org/BreadcrumbList">
                        {breadcrumbs.length ? this.renderBreadcrumbList(breadcrumbs) : this.renderBreadcrumb({}, 0)}
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default Breadcrumbs;
