import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import AdvoxSlider from 'Component/AdvoxSlider';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import ProductCard from 'SourceComponent/ProductCard';
import { ProductType } from 'SourceType/ProductList';

import { PRODUCTS_SLIDER_SETTINGS } from './ProductsSlider.config';

import './ProductsSlider.style';

/** @namespace AdvoxBasic/Component/ProductsSlider/Component/ProductsSlider */
export class ProductsSlider extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        items: PropTypes.arrayOf(ProductType),
        settings: PropTypes.object,
        headingSide: PropTypes.oneOf(['left', 'center', 'right']),
    };

    static defaultProps = {
        items: [],
        settings: PRODUCTS_SLIDER_SETTINGS,
        headingSide: 'center',
    };

    state = {
        siblingsHaveBrands: false,
        siblingsHavePriceBadge: false,
        siblingsHaveTierPrice: false,
        siblingsHaveConfigurableOptions: false,
    };

    containerProps() {
        const { siblingsHaveBrands, siblingsHavePriceBadge, siblingsHaveTierPrice, siblingsHaveConfigurableOptions } =
            this.state;

        return {
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true }),
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions,
            },
        };
    }

    render() {
        const { items, heading, isLoading, settings, headingSide } = this.props;

        if (isLoading) {
            return <div block="ProductsSlider" elem="Placeholder" />;
        }

        if (items?.length === 0) {
            return null;
        }

        return (
            <div block="ProductsSlider">
                <AdvoxSlider
                    variant="products"
                    block="ProductsSlider"
                    elem="Slider"
                    heading={heading}
                    headingSide={headingSide}
                    settings={settings}
                >
                    {items?.length &&
                        items.map((product, i) => (
                            <ProductCard
                                product={product}
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                selectedFilters={{}}
                                layout={GRID_LAYOUT}
                                {...this.containerProps()}
                            />
                        ))}
                </AdvoxSlider>
            </div>
        );
    }
}

export default withRouter(ProductsSlider);
