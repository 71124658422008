import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace AdvoxBasic/Component/ProductPrice/Component/ProductPrice */
export class ProductPrice extends SourceProductPrice {
    render() {
        const {
            price: { minimum_price: { final_price, regular_price } = {} } = {},
            discountPercentage,
            formattedFinalPrice,
            mix,
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        const hasDiscount = discountPercentage > 0;

        return (
            <div
                block="ProductPrice"
                mods={{ hasDiscount }}
                mix={mix}
                aria-label={`Product price: ${formattedFinalPrice}`}
            >
                {this.renderCurrentPrice()}
                {this.renderOldPrice()}
                {this.renderSubPrice()}
                {this.renderSchema()}
            </div>
        );
    }
}

export default ProductPrice;
