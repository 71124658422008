import { SearchIcon as SourceSearchIcon } from 'SourceComponent/SearchIcon/SearchIcon.component';

import './SearchIcon.override.style';

/** @namespace AdvoxBasic/Component/SearchIcon/Component/SearchIcon */
export class SearchIcon extends SourceSearchIcon {
    // TODO implement logic
}

export default SearchIcon;
