import BrowserDatabase from 'Util/BrowserDatabase';

export { ONE_HOUR, setCurrency } from 'SourceUtil/Currency/Currency';

export const CUR_CURRENCY = 'current_currency';

/** @namespace AdvoxBasic/Util/Currency/getCurrency */
export const getCurrency = () => {
    const config = BrowserDatabase.getItem('config');
    const currency = BrowserDatabase.getItem(CUR_CURRENCY) || config?.storeConfig?.default_display_currency_code;

    return typeof currency === 'string' ? currency : '';
};
