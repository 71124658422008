export * from 'SourceUtil/Cart/Cart';

/** @namespace AdvoxBasic/Util/Cart/getItemsCountLabel */
export const getItemsCountLabel = (items_qty) => {
    if (items_qty) {
        switch (items_qty) {
            case 1:
                return __('1 item');
            case 2:
            case 3:
            case 4:
                return __('%s plural items', items_qty);
            default:
                return __('%s items', items_qty);
        }
    }
};
