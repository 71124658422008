import InputMask from 'react-input-mask';

import { FieldInput as SourceFieldInput } from 'SourceComponent/FieldInput/FieldInput.component';

import { PROPS_TO_REMOVE } from './FieldInput.config';

/** @namespace AdvoxBasic/Component/FieldInput/Component/FieldInput */
export class FieldInput extends SourceFieldInput {
    // remove wrong input attributes to avoid browser's console errors
    prepareValidProps(initialProps) {
        const propsObj = Object.keys(initialProps)
            .filter((key) => !PROPS_TO_REMOVE.includes(key))
            .reduce((cur, key) => Object.assign(cur, { [key]: initialProps[key] }), {});

        return propsObj;
    }

    renderInputMask() {
        const { inputMask, formRef, maskChar, autocomplete, maskRegExp, ...validProps } = this.props;
        const cleanInputProps = this.prepareValidProps(validProps);
        const { disabled } = cleanInputProps || {};
        return (
            <InputMask mask={inputMask} maskChar={maskChar} {...cleanInputProps}>
                {(inputProps) => (
                    <input
                        ref={formRef}
                        autoComplete={autocomplete}
                        data-mask-regexp={maskRegExp}
                        disabled={disabled}
                        {...inputProps}
                    />
                )}
            </InputMask>
        );
    }

    renderInput() {
        const { inputMask, formRef, autocomplete, ...validProps } = this.props;

        const cleanInputProps = this.prepareValidProps(validProps);

        return inputMask && inputMask.length > 0 ? (
            this.renderInputMask()
        ) : (
            <input
                ref={formRef}
                autoComplete={autocomplete}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...cleanInputProps}
            />
        );
    }

    render() {
        return this.renderInput();
    }
}

export default FieldInput;
