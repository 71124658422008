import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import { PRODUCT_LABEL_TYPE, PRODUCT_LABEL_VARIANT } from './ProductLabel.config';

import './ProductLabel.style';

/** @namespace AdvoxBasic/Component/ProductLabel/Component/ProductLabel */
export class ProductLabel extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(Object.values(PRODUCT_LABEL_TYPE)),
        text: PropTypes.string,
        side: PropTypes.oneOf(['left', 'right']),
        radius: PropTypes.oneOf(['', 'left', 'right']),
    };

    static defaultProps = {
        type: 'default',
        side: 'right',
        radius: '',
    };

    renderImageLabel() {
        const { side, label: { image, text, url } = {} } = this.props;

        if (!image) {
            return null;
        }

        const mods = { variant: 'Image', side };

        if (url) {
            return (
                <Link to={url} block="ProductLabel" mods={mods}>
                    <img src={image} alt={text} />
                </Link>
            );
        }

        return (
            <div block="ProductLabel" mods={mods}>
                <img src={image} alt={text} />
            </div>
        );
    }

    renderTextLabel() {
        const {
            type,
            text,
            side,
            radius,
            label: { text: label_text, url, text_color, text_background_color } = {},
        } = this.props;
        const labelText = text || label_text;

        if (!labelText) {
            return null;
        }

        const mods = { variant: 'Text', type, side, radius };
        const style = {
            ...(text_color && { color: text_color }),
            ...(text_background_color && { backgroundColor: text_background_color }),
        };

        if (url) {
            return (
                <Link to={url} block="ProductLabel" mods={mods} style={style}>
                    {labelText}
                </Link>
            );
        }

        return (
            <div block="ProductLabel" mods={mods} style={style}>
                {labelText}
            </div>
        );
    }

    render() {
        const { label = {} } = this.props;
        const { label_type } = label;

        if (label_type === PRODUCT_LABEL_VARIANT.IMAGE) {
            return this.renderImageLabel();
        }

        return this.renderTextLabel();
    }
}

export default ProductLabel;
