import { Field } from 'Util/Query';

/** @namespace AdvoxBasic/Query/Brands/Query/BrandsQuery */
export class BrandsQuery {
    getBrandsQuery(options) {
        const { isFeatured, storeId, pageSize = 999, filter = {} } = options;
        const featuredFilter = {
            is_featured: {
                eq: '1',
            },
        };

        return new Field('mpbrand')
            .addArgument('filter', 'MageplazaBrandsFilterInput', isFeatured ? featuredFilter : filter)
            .addArgument('storeId', 'Int', storeId)
            .addArgument('pageSize', 'Int', pageSize)
            .addField(isFeatured ? this._getBrandsItemsField() : this._getSingleBrandFields());
    }

    _getBrandsItemsField() {
        return new Field('items').addFieldList(['option_id', 'value', 'meta_description', 'image', 'url_key']);
    }

    _getSingleBrandFields() {
        return new Field('items').addFieldList(['value', 'meta_title', 'image', 'url_key', 'option_id', 'description']);
    }
}

export default new BrandsQuery();
